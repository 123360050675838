import React from 'react';
import "../styles/Footer.css"

const Footer = () => {
  return (
    <div className='footer'>
      <p>&copy; 2023 Panda Universe. All rights reserved.</p>
    </div>
  );
};

export default Footer;
