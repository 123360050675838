import styles from '../styles/introduction.module.scss';
import classNames from 'classnames';

const Introduction = () => {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.transitionImage, styles.transitionImageTop)}>
        <div className={styles.image}>
          <img src="/images/waveone.svg" alt="Transition" layout="fill"></img>
        </div>
      </div>
      <div className={styles.innercontainer}>
        <div className={styles.logo}>
          <div className={styles.image}>
          <a href="https://pandauniverse.xyz" rel="noreferrer" target="_blank">
            <img src="/images/logo.jpg" alt="Panda Universe logo" layout="fill" />
            </a>
          </div>
        </div>
        <p className={styles.title}>Panda Universe</p>
        <p className={styles.description}>Community driven NFT project powered by the Polygon Network!</p>
        <p className={styles.launch}>GAME BETA IN DEVELOPMENT</p>

        <div className={styles.icons}>
          <div className={styles.icon}>
            <a href="https://discord.gg/fkUb2kxRZU" rel="noreferrer" target="_blank">
              <div className={styles.image}>
                <img src="/images/discord-icon.png" layout="fill" alt="Join our discord"></img>
              </div>
            </a>
          </div>
          <div className={styles.icon}>
            <a href="https://opensea.io/collection/pandauniverse" rel="noreferrer" target="_blank">
              <div className={styles.image}>
                <img src="/images/opensea.png" layout="fill" alt="Buy on OpenSea"></img>
              </div>
            </a>
          </div>
          <div className={styles.icon}>
            <a href="https://twitter.com/PandaMetaverse" rel="noreferrer" target="_blank">
              <div className={styles.image}>
                <img src="/images/twitter-icon.png" layout="fill" alt="Join our twitter"></img>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
