import styles from '../styles/roadmap.module.scss';
import classNames from 'classnames';

const RoadMap = () => {
  return (
    <div id="roadmap" className={styles.container}>
      <div className={styles.innercontainer}>
        <p className={styles.title}>Roadmap</p>
        <p className={styles.titledescription}>
          Our roadmap will activate as we hit strategic milestones in the Panda Universe. Each phase will strategically
          implement a new part of our project and plan to achieve our goals and bring success to the community!
        </p>

        <div className={styles.roadmap}>
          <div className={styles.middleline}>
            <div></div>
          </div>
          <div className={styles.roadmapitems}>
            <div className={classNames(styles.item, styles.left, styles.strikethrough)}>
              <p className={styles.title}>Phase 0</p>
              <p className={styles.subtitle}>Whitelist opportunity</p>
              <p className={styles.description}>
                You&apos;re here now! The community is growing and you have the opportunity to get whitelisted to mint
                the Pandas before everyone else!
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.right, styles.strikethrough)}>
              <p className={styles.title}>Phase 1</p>
              <p className={styles.subtitle}>Pre-mint and whitelist closure</p>
              <p className={styles.description}>
                Whitelist is closed and pre-mint sales begin! All of our whitelisted Pandas will have an opportunity to
                mint prior to public mint with a chance to mint super rare Pandas and be entered into whitelist only
                giveaways prior to public sale!
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.left, styles.strikethrough)}>
              <p className={styles.title}>Phase 2</p>
              <p className={styles.subtitle}>Public launch</p>
              <p className={styles.description}>
                Whitelist giveaways are over and public mint is here! Everyone who may have missed any opportunity to
                mint a Panda with the whitelist can finally mint! Minting limit is 5 per wallet.
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.right, styles.strikethrough)}>
              <p className={styles.title}>Phase 3</p>
              <p className={styles.subtitle}>20% minted</p>
              <p className={styles.description}>
                It’s time to start the giveaways again! Every week after Phase 3 we’ll start our giveaways again! Stay
                active in the discord to find out if you’re the lucky winner!
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.left, styles.strikethrough)}>
              <p className={styles.title}>Phase 4</p>
              <p className={styles.subtitle}>40% minted</p>
              <p className={styles.description}>
                We begin our monthly charitable donation with 10% of our royalties to charities of the community&apos;s
                choosing!
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.right)}>
              <p className={styles.title}>Phase 5</p>
              <p className={styles.subtitle}>60% minted</p>
              <p className={styles.description}>
                We launch a professional marketing campaign on social media for Panda Universe!
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.left, styles.strikethrough)}>
              <p className={styles.title}>Phase 6</p>
              <p className={styles.subtitle}>80% minted</p>
              <p className={styles.description}>
                We begin our airdrop campaign to all Panda holders! Watch for announcements on Discord for more
                information!
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.right, styles.strikethrough)}>
              <p className={styles.title}>Phase 7</p>
              <p className={styles.subtitle}>100% minted</p>
              <p className={styles.description}>
                We celebrate! We launch a series of celebratory giveaways for all the holders in the community to take
                part in! Developer insights of the P2E game (PandaVerse) will be released.
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.left)}>
              <p className={styles.title}>Phase 8</p>
              <p className={styles.subtitle}>PandaVerse BETA</p>
              <p className={styles.description}>
                We begin closed beta testing of our P2E game and solicit feedback from the community to improve the game
                and user experience!
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
            <div className={classNames(styles.item, styles.right)}>
              <p className={styles.title}>Phase 9</p>
              <p className={styles.subtitle}>PandaVerse Release</p>
              <p className={styles.description}>
                Play to Earn game PandaVerse is released and Roadmap V2 is developed.
              </p>
              <div className={styles.timelineIndicator}>
                <div className={styles.timelineIndicatorCircle}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
