import styles from '../styles/whatis.module.scss';

const WhatIs = () => {
  return (
    <div id="about" className={styles.container}>
      <div className={styles.innercontainer}>
        <div className={styles.text}>
          <p className={styles.title}>What is Panda Universe?</p>
          <p className={styles.description}>
            Panda Universe is a NFT collection that is committed to giving back to the community. Each of our 9450
            Pandas will be minted with unique and interesting attributes that will not only be fun for the community but
            will bring them utility in our PvE/PvP game PandaVerse!
            <br />
            <br />
            The pandas are live on the Polygon Network (MATIC).
            <br />
            <br />
            We are dedicated to our community and are working to deliver a quality gaming experience.
          </p>
          <a href="https://discord.gg/fkUb2kxRZU">
            <button className={styles.getstarted} type="button">
              Get started
            </button>
          </a>
        </div>
        <div className={styles.images}>
          <div className={styles.image}>
            <img src="/images/panda-gif-v2.gif" layout="fill" alt="The amazing pandas"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIs;
