import styles from '../styles/theteam.module.scss';

const TheTeam = () => {
  return (
    <div id="team" className={styles.container}>
      <div className={styles.innercontainer}>
        <p className={styles.title}>The Team</p>
        <p className={styles.description}>The amazing team powering the Panda Universe!</p>

        <div className={styles.teamContainer}>
          <div className={styles.innerContainer}>
            <div className={styles.teammember}>
              <div className={styles.insideTeamMember}>
                <div className={styles.teamImage}>
                  <div className={styles.image}>
                    <img src="/images/team/deniz.png" layout="fill" alt="Team member Deniz" />
                  </div>
                </div>
                <p className={styles.teamName}>DENIZ</p>
                <p className={styles.teamRole}>Founder & Developer</p>
              </div>
            </div>
            <div className={styles.teammember}>
              <div className={styles.insideTeamMember}>
                <div className={styles.teamImage}>
                  <div className={styles.image}>
                    <img src="/images/team/enlighten.png" layout="fill" alt="Team member enlighten" />
                  </div>
                </div>
                <p className={styles.teamName}>enlighten</p>
                <p className={styles.teamRole}>Project Manager & Developer</p>
              </div>
            </div>
            <div className={styles.teammember}>
              <div className={styles.insideTeamMember}>
                <div className={styles.teamImage}>
                  <div className={styles.image}>
                    <img src="/images/team/arc.jpg" layout="fill" alt="Team member ArcBurn" />
                  </div>
                </div>
                <p className={styles.teamName}>ArcBurn</p>
                <p className={styles.teamRole}>Game Design & Development</p>
              </div>
            </div>
            <div className={styles.teammember}>
              <div className={styles.insideTeamMember}>
                <div className={styles.teamImage}>
                  <div className={styles.image}>
                    <img src="/images/team/sdreaver.png" layout="fill" alt="Team member SDreaver" />
                  </div>
                </div>
                <p className={styles.teamName}>SDreaver</p>
                <p className={styles.teamRole}>Community manager</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheTeam;
