import styles from '../styles/pandaverse.module.scss';

const Pandaverse = () => {
  return (
    <div id="pandaverse" className={styles.container}>
      <div className={styles.innercontainer}>
        <div className={styles.images}>
          <div className={styles.image}>
            <img src="/images/thepvparena.jpeg" layout="fill" alt="The amazing pandas"></img>
          </div>
        </div>
        <div className={styles.text}>
          <p className={styles.title}>Pandaverse - P2E Game</p>
          <p className={styles.description}>
            Pandaverse will be a PvE/PvP game where Panda holders can battle other Pandas, complete tasks in
            the Pandaverse and much more! All this while earning in game tokens.
            <br />
            <br />
            And craziest of all - You can be your own manager and start a scholarship program!
          </p>
          <a href="https://discord.gg/fkUb2kxRZU">
            <button className={styles.getstarted} type="button">
              Learn more
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Pandaverse;
