import styles from '../styles/faq.module.scss';
import { useState } from 'react';

const FAQ = () => {
  const faqs = [
    {
      question: 'What network is used?',
      answer:
        'The Panda Universe runs on the Polygon Network and costs POLYGON MATIC to mint. The Polygon network ensures MUCH lower fees than Ethereum Network.',
    },
    {
      question: 'What marketplace can be used?',
      answer:
        'The Panda Universe and be sold and bought on markets that supports the Polygon Network. This includes markets such as OpenSea, Rarible, and Magic Eden.',
    },
    {
      question: 'How much was minting cost?',
      answer: 'Minting was 0 MATIC + gas fee! On February 5th 2022, we only paid gas to mint the Pandas!',
    },
    {
      question: 'How can I buy MATIC? (Polygon Network)',
      answer:
        'You can buy MATIC for the Polygon Network most of exchanges. Keep in mind Coinbase, does NOT support MATIC for Polygon! If you want to purchase outside an exchange, you can use sites like this one: https://buy.moonpay.com/',
    },
    {
      question: 'What are the benefits of holding?',
      answer:
        'Automatically entered in giveaways, possible passive income for a year and air drops! You also get access to our PvE/PvP Game, PandaVerse',
    },
    {
      question: 'How do you fund the project?',
      answer:
        'The project is mainly funded using intial investments from the team itself. The project will be funded by our royalties as we move further with the project.',
    },
  ];

  const [openFAQs, setOpenFAQs] = useState(Array(faqs.length).fill(false));

  return (
    <div id="faq" className={styles.container}>
      <div className={styles.innercontainer}>
        <p className={styles.title}>FAQ</p>
        <p className={styles.description}>Any questions? Feel free to look below or message us on Discord!</p>

        <div className={styles.faqs}>
          {faqs.map((faq, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  console.log(newOpenFAQs);
                  const newOpenFAQs = [...openFAQs];
                  newOpenFAQs[i] = !newOpenFAQs[i];
                  setOpenFAQs(newOpenFAQs);
                }}
                className={styles.faq}
              >
                <p className={styles.question}>+ {faq.question}</p>
                <p className={`${styles.answer} ${openFAQs[i] ? styles.open : ''}`}>{faq.answer}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;