import styles from '../styles/navbar.module.scss';
import React from 'react';

const NavBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <a href="#home">
          <p className={styles.home}>Home</p>
        </a>
        <a href="#about">
          <p className={styles.about}>About</p>
        </a>
        <a href="#pandaverse">
          <p className={styles.pandaverse}>Pandaverse</p>
        </a>
        <a href="#roadmap">
          <p className={styles.roadmap}>Roadmap</p>
        </a>
        <a href="#team">
          <p className={styles.team}>Team</p>
        </a>
        <a href="#faq">
          <p className={styles.faq}>FAQ</p>
        </a>
      </div>
    </div>
  );
};

export default NavBar;
