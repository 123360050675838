import React from 'react';
import NavBar from '../components/Navbar.jsx';
import RoadMap from '../components/roadmap.jsx';
import WhatIs from '../components/whatis.jsx';
import WhyBuy from '../components/whybuy';
import Pandaverse from '../components/pandaverse.jsx';
import TheTeam from '../components/theteam.jsx';
import FAQ from '../components/faq.jsx';
import Links from '../components/links.jsx';
import Introduction from '../components/introduction.jsx';
import Footer from '../components/Footer.jsx';
import styles from '../styles/Home.module.scss';

const Home = () => {
  return (
    <div id="home">
      <div className={styles.bambooimage}>
        <div className={styles.image}>
          <img src="/images/bambooclipart.png" alt="bamboo"></img>
        </div>
      </div>
      <NavBar />
      <div className={styles.container}>
        <Introduction />
        <WhatIs />
        <WhyBuy />
        <Pandaverse />
        <RoadMap />
        <TheTeam />
        <FAQ />
        <Links />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
