import styles from '../styles/whybuy.module.scss';

const WhyBuy = () => {
  return (
    <div id="whybuy" className={styles.container}>
      <div className={styles.innercontainer}>
        <p className={styles.title}>Why should I hold a Panda?</p>
        <p className={styles.description}>
          There are many reasons why you should hold a Panda! Take a look at some of them!
        </p>

        <div className={styles.reasonContainer}>
          <div className={styles.reason}>
            <div className={styles.reasoninner}>
              <div className={styles.imageContainer}>
                <div className={styles.image}>
                  <img src="/images/icons/money.svg" alt="Royalty return" layout="fill" />
                </div>
              </div>
              <p className={styles.bigText}>10%</p>
              <p className={styles.smallText}>Royalty return</p>
              <p className={styles.reasonDescription}>
                100 randomly chosen Pandas among the first minted will receive 10% of our royalites for one complete
                year!
              </p>
            </div>
          </div>
          <div className={styles.reason}>
            <div className={styles.reasoninner}>
              <div className={styles.imageContainer}>
                <div className={styles.image}>
                  <img src="/images/icons/charity.svg" alt="Royalty return" layout="fill" />
                </div>
              </div>
              <p className={styles.bigText}>10%</p>
              <p className={styles.smallText}>Royalty to charity</p>
              <p className={styles.reasonDescription}>
                Feel good about yourself! Every month the community will vote for a charity to donate 10% of our
                royalties to!
              </p>
            </div>
          </div>
          <div className={styles.reason}>
            <div className={styles.reasoninner}>
              <div className={styles.imageContainer}>
                <div className={styles.image}>
                  <img src="/images/icons/airdrop.svg" alt="Royalty return" layout="fill" />
                </div>
              </div>
              <p className={styles.smallText}>Airdrops</p>
              <p className={styles.reasonDescription}>
                All of our holders will be eligible for regular airdrops from our development team!
              </p>
            </div>
          </div>
          <div className={styles.reason}>
            <div className={styles.reasoninner}>
              <div className={styles.imageContainer}>
                <div className={styles.image}>
                  <img src="/images/icons/game.svg" alt="Royalty return" layout="fill" />
                </div>
              </div>
              <p className={styles.smallText}>Access to Game</p>
              <p className={styles.reasonDescription}>
                Get access to our PvE/PvP game PandaVerse and earn in game tokens by battling other Pandas, and completing
                tasks!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyBuy;
