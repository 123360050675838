import styles from '../styles/links.module.scss';

const Links = () => {
  return (
    <div id="links" className={styles.container}>
      <div className={styles.innercontainer}>
        <p className={styles.title}>Links</p>
        <p className={styles.description}>Feel free to get in contact with us using the links below!</p>

        <div className={styles.icons}>
          <div className={styles.icon}>
            <a href="https://discord.gg/fkUb2kxRZU" rel="noreferrer" target="_blank">
              <div className={styles.image}>
                <img src="/images/discord-icon.png" layout="fill" alt="Join our discord"></img>
              </div>
            </a>
          </div>
          <div className={styles.icon}>
            <a href="https://opensea.io/collection/pandauniverse" rel="noreferrer" target="_blank">
              <div className={styles.image}>
                <img src="/images/opensea.png" layout="fill" alt="Buy and sell on OpenSea"></img>
              </div>
            </a>
          </div>
          <div className={styles.icon}>
            <a href="https://twitter.com/PandaMetaverse" rel="noreferrer" target="_blank">
              <div className={styles.image}>
                <img src="/images/twitter-icon.png" layout="fill" alt="Join our twitter"></img>
              </div>
            </a>
          </div>
        </div>
        <p className={styles.legalLinks}>
        <a href="https://pandauniverse.xyz"  target="_blank">Marketplace</a>
          <a href="/files/legal_23_jan.pdf" target="_blank" rel="noopener noreferrer">
            Terms & Conditions
          </a>
          <a href="https://ranknft.io/collection/pandauniverse"  target="_blank">NFT Rarity</a>
        </p>
      </div>
    </div>
  );
};

export default Links;
